(function(){
	'use strict';

	var contentItems = [].slice.call(document.querySelectorAll('[data-content]'));
	var navigation = document.querySelector('[data-navigation]');
	var navigationToggle = document.querySelector('[data-navigation-toggle]');

	if(navigationToggle) {
		navigationToggle.addEventListener('click', function(e) {
			navigation.classList.toggle('navigation-active');

			contentItems.forEach(function(element) {
				element.classList.toggle('main-content-inactive');
			});
		});
	}
}());
